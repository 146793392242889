<template>
	<div class="ele-body">
		<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table.where" class="ele-form-search d-flexspabet mt-20"
						@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
						<div style="margin-bottom: 20px;">
							<!-- <el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
								v-if="permission.includes('sys:limitnumbers:add')">添加限号设置
							</el-button> -->
							<el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small" v-if="permission.includes('sys:limitNumUsers:add')">添加黑名单</el-button>
						</div>
						<div class="d-flex">
							<!-- <el-form-item label="省:" label-width="35px" class="w-170">
								<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
									placeholder="请选择省" clearable>
									<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
										:key="option.pid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table.where.cid"
									@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder="请选择市" clearable>
									<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
										:key="option.cid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200">
								<el-select v-model="table.where.aid" placeholder="请选择县/区" @change="$forceUpdate()" clearable>
									<el-option v-for="option in districtArr" :label="option.name" :value="option.aid"
										:key="option.aid"></el-option>
								</el-select>
							</el-form-item> -->
							<el-form-item  style="display: flex ; align-items: center; padding-left: 10px;">
								<el-input v-model="table.where.phone" placeholder="请输入号码" clearable />
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
									class="ele-btn-icon ml-20">搜索</el-button>
								<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center" show-overflow-tooltip />
						<!-- 	<el-table-column label="司机类型" show-overflow-tooltip min-width="120" align="center" >
								<template slot-scope="scope">
							{{scope.row.driver_type==1?'租车':(scope.row.driver_type==2 ? '车主':'/')}}
								</template>
							</el-table-column> -->
							<!-- <el-table-column prop="true_name" label="用户姓名" show-overflow-tooltip min-width="80" /> -->
							<el-table-column prop="phone" label="号码" show-overflow-tooltip min-width="120" />
							<!-- <el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="120" /> -->
							<!-- <el-table-column prop="driver_area" label="所属地区" show-overflow-tooltip min-width="140" /> -->
							<!-- <el-table-column prop="cancel_order_count" label="取消订单次数(次)" show-overflow-tooltip min-width="120" /> -->
							<!-- <el-table-column prop="time_out_num" label="超时次数(次)" show-overflow-tooltip min-width="120" /> -->
							<!-- <el-table-column prop="title_day" label="限号天数(天)" show-overflow-tooltip min-width="120" /> -->
							<el-table-column prop="create_time" label="添加时间" sortable="custom" show-overflow-tooltip min-width="160">
							</el-table-column>
						<!-- 	<el-table-column label="限号截止时间" sortable="custom" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">  {{row.remark_data  == '' ?    (row.title_time): '/' }} </template>
							</el-table-column> -->
							<!-- <el-table-column prop="remark_data" label="备注" show-overflow-tooltip min-width="120" /> -->
<!--							<el-table-column prop="type_status" label="状态" show-overflow-tooltip min-width="120" />-->
							<el-table-column label="操作" align="center" :resizable="false" min-width="60">
								<template slot-scope="{row}">
									<!-- <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:limitnumbers:edit')">编辑</el-link> -->
								<!-- 	<el-link slot="reference" @click="handleSetttatus(row)" v-if="permission.includes('sys:limitnumbers:open')"
										:icon="row.limit_status==1?'el-icon-error':'el-icon-success'"
										:type="row.limit_status==1?'danger':'primary'" :underline="false">
										{{row.limit_status==1?'关闭':'开启'}}
									</el-link> -->
									<el-popconfirm title="确定要删除此信息吗？" @confirm="remove(row)" class="ele-action">
										<el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false"
											v-if="permission.includes('sys:limitnumbers:delete')">删除</el-link>
									</el-popconfirm>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
		</el-card>
		
		<!--添加/编辑弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id?'编辑黑名单号码':'添加黑名单号码'" 
		:visible.sync="showEdit" @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="160px">
					<!-- <div>
						<el-form-item label="请选择地区：" v-if="editForm.id" prop="pid">
							<el-select v-model="editForm.pname" class="selectStyle mr-10 mb-20" placeholder="请选择省" disabled
								v-if="editForm.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" class="selectStyle mr-10 mb-20" placeholder="请选择市" disabled
								v-if="editForm.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" class="selectStyle mb-20"
								:class="(editForm.pname && editForm.cname)?'mr-10':''" placeholder="请选择区/县" disabled
								v-if="editForm.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="请选择地区：" v-if="!editForm.id" prop="pid">
							<el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
								class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cid" @change="handleChangeCity1(editForm.cid)"
								class="selectStyle mr-10 mb-20" placeholder="请选择市" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aid" @change="$forceUpdate(),handleDis(editForm.aid)"
								class="selectStyle mr-10 mb-20" placeholder="请选择区/县" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div> -->

					<!-- <el-form-item label="限号身份类型：" prop="limit_type">
						<el-select v-model="editForm.limit_type" placeholder="请选择限号身份类型" class="input216 " clearable>
							<el-option v-for="option in limitTypeArr" :value="option.type" :key="option.type"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item> -->
					<!-- <el-form-item label="取消订单次数(次)：" prop="num">
						<el-input v-model.number="editForm.num" controls-position="right" placeholder="" clearable
							class="input163" />
						次/月
					</el-form-item> -->
				<!-- 	<el-form-item label="订单超时次数(次)："
						:style="(editForm.limit_type==2||editForm.limit_type==6)?'display:none':''">
						<el-input v-model.number="editForm.timeout_num" controls-position="right" placeholder="" clearable
							class="input163" />
						次
					</el-form-item> -->
					<el-form-item label="号码：" prop="phone_data">
						<el-input type="textarea" :autosize="{minRows: 10, maxRows: 10}" 
						maxlength="2399" show-word-limit
						v-model="editForm.phone_data" controls-position="right" placeholder="一行一个号码" clearable
							class="input316"/>
            <div style="margin-top: 10px;font-size: 12px;color: #999;">单次最多添加200个号码</div>
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "SysLimitNumbers",
		data() {
			return {
				table: {
					url: '/Accessdenied/searchData',
					where: {}
				}, // 表格配置
				table1: {
					url: '/store/restrictList',
					where: {}
				},
				// 司机限号列表
				table2: {
					url: 'driver/driver_limit_list',
					where: {}
				},
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				limitTypeArr:[
					{
						type:'2',
						name:'司机版'
					},{
						type:'3',
						name:'跑腿版'
					},{
						type:'4',
						name:'超市版'
					},
					{
						type:'5',
						name:'叫餐版'
					},
					// {
					// 	type:'6',
					// 	name:'司机'
					// },{
					// 	type:'7',
					// 	name:'跑腿'
					// },{
					// 	type:'1',
					// 	name:'超市'
					// },{
					// 	type:'2',
					// 	name:'叫餐'
					// }
					],
				editRules: {  // 表单验证规则
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}],
					limit_type: [
						{required: true, message: '请选择取消订单次数', trigger: 'change'}
					], 
					num: [
						{required: true, message: '请输入取消订单次数', trigger: 'blur'}
					],
					timeout_num: [
						{required: true, message: '请输入订单超时次数', trigger: 'blur'}
					],
					day: [
						{required: true, message: '请输入限号天数', trigger: 'blur'}
					],
					phone_data: [
						{required: true, message: '请输入号码', trigger: 'blur'}
					]
				},
				isType: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				activeName: 'first',
				activeEdit: 'run-driver',
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
			})

		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {


			handleSetttatus(row) {
				if (row.limit_status == 1) {
					this.$confirm('确定关闭限号设置?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/limitnumbers/set_status', {
							id: row.id,
							limit_status: 0
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});


					})
				} else {
					this.$confirm('确定开启限号设置?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						//console.log(row);
						this.$http.post('/limitnumbers/set_status', {
							id: row.id,
							limit_status: 1
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
								this.faceStart = '关闭'
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});

					})
				}
			},

			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			
			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				let that = this
				this.changePro = false
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					that.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cid = ''
					this.editForm.aid = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = false
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aid = ''
				})
				}else{
					this.editForm.pid = ''
					this.editForm.aid = ''
				}
			},
			handleDis() {
				this.changePro = false
			},
			/**
			 * 显示编辑
			 */
			edit(row) {
				this.$http.get('/limitnumbers/info?id=' + row.id).then(res => {
					if (res.data.code === 0) {
						this.limitType = res.data.data.limit_type
						this.editForm = res.data.data;
						this.showEdit = true;
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			editLimit(row){
				this.$http.get('/store/editRelieveRestrict?id=' + row.id).then(res => {
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table1.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			// 司机限号解除
			driverEditLimit(row){
				this.$http.get('/driver/tolimit?id=' + row.id).then(res => {
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table2.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			// 切换显示版
			// handleType() {
			// 	this.isType = true
			// },
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						// if(this.editForm.limit_type !==2 && this.editForm.limit_type !==6 && !this.editForm.timeout_num){
						// 	this.$message.error("请输入订单超时次数");
						// }else{
							const loading = this.$loading({lock: true});
							var editFormObj = {}
							// editFormObj.pid = this.editForm.pid
							// editFormObj.cid = this.editForm.cid
							// editFormObj.aid = this.editForm.aid
							// editFormObj.timeout_num = this.editForm.timeout_num
							// editFormObj.limit_type = this.editForm.limit_type
							// editFormObj.num = this.editForm.num
							// editFormObj.day = this.editForm.day
							// editFormObj.id = this.editForm.id
							editFormObj.phone_data = this.editForm.phone_data
							this.$http.post('/Accessdenied/addUserAccessDenied', editFormObj).then(res => {
								loading.close();
								if (res.data.code === 0) {
									this.$message({
										type: 'success',
										message: res.data.msg
									});
									this.$refs.table.reload();
									this.showEdit = false;
								} else {
									this.$message.error(res.data.msg);
                  console.log('res99999999999999data',res.data);
                  this.editForm.phone_data=res.data.data.failure;
                
                  
								}
							}).catch(e => {
								loading.close();
								this.$message.error(e.message);
							});
						// }
						
					} else {
						return false;
					}
				});
			},
			/**
			 * 刪除(批量刪除)
			 */
			remove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的商家限号吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/limitnumbers/delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/Accessdenied/delData', {
						phone: row.phone
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}
			},

		}
	}
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}

	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}

	.driverForm /deep/.el-form-item__content {
		margin-left: 0 !important
	}

	/deep/.el-tabs__nav {
		margin-left: 20px
	}
	/deep/.el-dialog {
		width: 600px;
	}
</style>
